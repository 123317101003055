export const query = (parameters = {}) => {

    const keys = Object.keys(parameters).filter(v => {
        return v !== null && parameters[v] !== null && parameters[v] !== undefined
    })

    const query = keys.map((key) => {
        let value = parameters[key]
        if (Array.isArray(value)) {
            const values = value.filter((v) => v !== null && v !== undefined)
            value = values.join(',')
        }

        return key + '=' + value
    }).join('&')

    return query.length ? '?' + query : ''
}

export const interpolate = (base, values = {}) => {
    Object.entries(values).forEach(([k, v]) => {
        base = base.replace(`{{${k}}}`, v)
    })

    return base
}