export default {
    en: {
        general: {
            roles: {
                teachers: 'Employees',
                tutors: 'Tutors',
                students: 'Students',
                contacts: 'Parents',
                contact_persons: 'Parents',
                inspectors: 'Inspectors',
                teacher: 'Employee',
                tutor: 'Tutor',
                student: 'Student',
                contact: 'Parent',
                inspector: 'Inspector',
                all: {
                    teachers: 'All employees',
                    tutors: 'All tutors',
                    students: 'All students',
                    contacts: 'All parents',
                    contact_persons: 'All parents',
                    inspectors: 'All inspectors',
                },
            },
        },
        // Screens
        messageSuccessScreen: {
            headerTitle: 'Message sent',
            message: 'The message was sent successfully.',
            backText: 'Back to messages',
        },
        groupsOverviewScreen: {
            noGroupsHeading: 'No messages sent',
            noGroupsText: 'Send a message in case of important situations like canceled lessons, meetings, emergencies, etc.',
            toggleTitle: 'Messages',
            searchPlaceholder: 'Search for groups by name or class',
            noMatchingGroups: 'No matching groups.'
        },
        peopleOverviewScreen: {
            consentGiven: 'Consent given',
            consentUndecided: 'Consent undecided',
            consentNotGiven: 'Consent denied',
            missingPhone: 'Missing phone number',
            toggleTitle: 'People',
            peopleSearchPlaceholder: 'Search by name, initials or UNI-ID',
            amountDescription: '{{count}} people',
            consentBoxSubheaderSelectedRecipients: 'Selected recipients',
            consentBoxSubheaderPeople: 'All people',
        },
        selectSchoolScreen: {
            headerTitle: 'SMS Widget',
            screenHeading: 'Choose school',
            screenTagline: 'Choose the school you wish to see and send messages for.',
            blacklistReasonHeader: 'School is currently blocked from sending messages',
            blacklistReasonMessage: 'You cannot view or send messages for the selected school, for the following reasons:',
            blacklistReasonBack: 'Back',
        },
        newMessageScreen: {
            headerTitle: 'Send message',
            groupNameHeading: 'Name of the group',
            selectRecipientsHeading: 'Recipients',
            maxCharWarning: 'You\'ve reached the limit of 10.000 characters',
        },
        selectRecipientsScreen: {
            headerTitle: 'Select recipients',
            done: 'DONE',
            peopleSearchPlaceholder: 'Search by name, initials or UNI-ID',
            classSearchPlaceholder: 'Search by class name',
            peopleRemoved: 'All selected people removed',
            undo: 'Undo',
            consentBoxSubheaderSelectedRecipients: 'Selected recipients',
            consentBoxSubheaderPeople: 'All people',
            peopleAmount: '{{count}} people',
            resultsAmount: '{{count}} results',
            people: 'People',
            groups: 'Groups',
            classes: 'Classes',
        },
        groupMessagesScreen: {
            pendingTooltip: 'The message is pending for {{count}} people.',
            successTooltip: 'The message is delivered to {{count}} people.',
            failedTooltip: 'The message failed for {{count}} people.',
            members: 'members',
        },
        groupMembersScreen: {
            groups: 'Groups',
            members: 'Members',
            consentBoxSubheader: 'Members',
            searchPlaceholder: 'Search by name, initials or UNI-ID',
        },
        messageRecipientsScreen: {
            headerTitle: 'Recipients',
            searchPlaceholder: 'Search by name, initials or UNI-ID',
            pending: 'Pending',
            received: 'Received',
            failed: 'Failed',
            noResults: 'No results',
        },
        errorScreen: {
            headerTitle: 'Error',
            heading: 'An error occurred.',
            text: 'An error occurred, which caused the application to crash. Please try to reload the page. If the issue persists please contact our support.',
        },
        unauthorizedScreen: {
            headerTitle: 'SMS Widget',
            heading: 'SMS Widget',
            text: 'Easily send out text messages to everyone from your school(s)',
            bullet1: 'Communicate important matters fast',
            bullet2: 'Keep everyone updated about any changes',
            bullet3: 'No restrictions on the number of messages you can send',
            contactBoxText: 'You\'re not registered into the widget. If you want to start using it or find out more about it, please contact our support!',
        },
        // Components
        selectedRecipients: {
            peopleSelected: '{{count}} people selected',
            clearSelected: 'Clear selected',
        },
        messageForm: {
            messageFormPlaceholder: 'Write message',
            lengthDescription: 'Message is {{characterCount}} characters long. {{messageCount}} message(s) will be sent per recipient.',
            utfDescription: 'The maximum character length per sms is decreased, since the message contains special characters or emojies.',
        },
        recipient: {
            consentGiven: 'Consent is given',
            consentCanBeGiven: 'Consent can be given',
            consentDenied: 'Consent denied',
            userDoesNotHavePhoneNumber: 'This user does not have a phone number',
            messageReceivedBy: 'Message was received by {{name}}',
            messagePendingFor: 'Message is pending for {{name}}',
            messageFailedFor: 'Message failed for {{name}}',
            otherRoles: 'Other roles',
            uniId: 'UNI-ID',
            giveConsentForUser: 'Give consent for user',
        },
        recipientType: {
            showMore: 'Show {{count}} more',
        },
        messageValidationErrorsModal: {
            heading: 'Can\'t send message',
            text: 'The following reasons prevents sending the message:',
            goBack: 'Go back',
        },
        confirmSendMessageModal: {
            header: 'Confirm sending message',
            receivesMessage: 'will receive the message',
            notReceiveMessageA: 'will',
            notReceiveMessageB: 'not',
            notReceiveMessageC: 'receive the message',
            messagesTotal: 'messages will be sent in total',
            haveNotConsented: 'have not consented',
            areMissingPhoneNumber: 'are missing phone number',
            cancel: 'Cancel',
            send: 'Send message',
        },
        consentBox: {
            header: 'Give consent',
            people: 'people',
            text: 'There are people who have not decided their consent yet. You can give consent on their behalf, so you can send messages to these people.',
            buttonText: 'Give consent',
        },
        confirmBackModal: {
            header: 'Are you sure?',
            text: 'Any progress and changes you have made will not be saved!',
            cancel: 'Cancel',
            confirm: 'Yes, go back!',
        },
        // Messages
        errors: {
            requestCreateGroupFailed: 'The request to create the group failed.',
            requestSendMessageFailed: 'The request to send the message failed.',
            requestBlacklisted: 'The school is currently blocked from sending messages. Please reload the page.',
            noSelectedRecipients: 'You must select at least one recipient or group.',
            groupNameTooLong: 'The entered group name is too long.',
            missingGroupName: 'Group must have a name',
            messageTextEmpty: 'Message cannot be empty',
            messageTextTooLong: 'Message cannot be longer than 10,000 characters',
        },
    },
    da: {
        general: {
            roles: {
                teachers: 'Medarbejdere',
                tutors: 'Vikarer',
                students: 'Elever',
                contacts: 'Forældre',
                contact_persons: 'Forældre',
                inspectors: 'Superbrugere',
                teacher: 'Medarbejder',
                tutor: 'Vikar',
                student: 'Elev',
                contact: 'Forælder',
                inspector: 'Superbruger',
                all: {
                    teachers: 'Alle medarbejdere',
                    tutors: 'Alle vikarer',
                    students: 'Alle elever',
                    contacts: 'Alle forældre',
                    contact_persons: 'Alle forældre',
                    inspectors: 'Alle superbrugere',
                },
            },
        },
        // Screens
        messageSuccessScreen: {
            headerTitle: 'Besked sendt',
            heading: 'Beskeden blev afsendt.',
            backText: 'Tilbage til oversigt.',
        },
        groupsOverviewScreen: {
            noGroupsHeading: 'Ingen beskeder sendt',
            noGroupsText: 'Send en besked i vigtige situationer som f.eks. aflyste lektioner, møder eller nødstilfælde.',
            toggleTitle: 'Beskeder',
            searchPlaceholder: 'Søg på trådnavn eller klasse',
            noMatchingGroups: 'Ingen grupper matchede din søgning.'
        },
        peopleOverviewScreen: {
            consentGiven: 'Samtykke givet',
            consentUndecided: 'Samtykke uafklaret',
            consentNotGiven: 'Samtykke afslået',
            missingPhone: 'Manglende tlf. nummer',
            toggleTitle: 'Personer',
            peopleSearchPlaceholder: 'Søg på navn, initialer eller UNI-ID',
            amountDescription: '{{count}} personer',
            consentBoxSubheaderSelectedRecipients: 'Valgte modtagere',
            consentBoxSubheaderPeople: 'Alle personer',
        },
        selectSchoolScreen: {
            headerTitle: 'SMS Widget',
            screenHeading: 'Vælg skole',
            screenTagline: 'Vælg skolen som du ønsker at se og sende beskeder for.',
            blacklistReasonHeader: 'Skole utilgængelig',
            blacklistReasonMessage: 'Skolen er utilgængelig på nuværende tidspunkt, grundet følgende årsager:',
            blacklistReasonBack: 'Tilbage',
        },
        newMessageScreen: {
            headerTitle: 'Send besked',
            groupNameHeading: 'Navngiv tråd',
            selectRecipientsHeading: 'Modtagere',
            maxCharWarning: 'Du kan ikke sende beskeder længere end 10.000 tegn.',
        },
        selectRecipientsScreen: {
            headerTitle: 'Vælg modtagere',
            done: 'OK',
            peopleSearchPlaceholder: 'Søg på navn, initialer eller UNI-ID',
            classSearchPlaceholder: 'Søg på klassenavn',
            peopleRemoved: 'Modtagere fjernet',
            undo: 'Fortryd',
            consentBoxSubheaderSelectedRecipients: 'Valgte modtagere',
            consentBoxSubheaderPeople: 'Alle personer',
            peopleAmount: '{{count}} personer',
            resultsAmount: '{{count}} resultater',
            people: 'Personer',
            groups: 'Grupper',
            classes: 'Klasser',
        },
        groupMessagesScreen: {
            members: 'medlemmer',
            pendingTooltip: 'Denne besked er afventende for {{count}} personer.',
            successTooltip: 'Denne besked blev modtaget af {{count}} personer.',
            failedTooltip: 'Denne besked fejlede for {{count}} personer.',
        },
        groupMembersScreen: {
            groups: 'Grupper',
            members: 'Medlemmer',
            consentBoxSubheader: 'Medlemmer',
            searchPlaceholder: 'Søg på navn, initialer eller UNI-ID',
        },
        messageRecipientsScreen: {
            headerTitle: 'Modtagere',
            searchPlaceholder: 'Søg på navn, initialer eller UNI-ID',
            pending: 'Afventer',
            received: 'Modtaget',
            failed: 'Mislykkedes',
            noResults: 'Ingen resultater',
        },
        errorScreen: {
            headerTitle: 'Fejl',
            heading: 'Der skete en fejl.',
            text: 'Der skete en uventet fejl. Forsøg venligst at genindlæse siden. Hvis problemet fortsætter, kontakt da venligst vores support.',
        },
        unauthorizedScreen: {
            headerTitle: 'SMS Widget',
            heading: 'SMS Widget',
            text: 'Send hurtigt og nemt tekstbeskeder til alle på skolen',
            bullet1: 'Kommunikér nemt vigtig information',
            bullet2: 'Hold alle på skolen opdateret ift. ændringer',
            bullet3: 'Ingen begrænsning i antallet af beskeder som kan sendes',
            contactBoxText: 'Du har ikke fået adgang til denne widget. Hvis du ønsker at benytte den, eller vide mere omkring den, kontakt da venligst vores support.',
        },
        // Components
        selectedRecipients: {
            peopleSelected: '{{count}} personer valgt',
            clearSelected: 'Ryd',
        },
        messageForm: {
            messageFormPlaceholder: 'Skriv besked',
            lengthDescription: 'Beskeden er på {{characterCount}} tegn. {{messageCount}} sms-besked(er) vil blive sendt per modtager.',
            utfDescription: 'Max beskedlængde er forkortet idet teksten indeholder specialtegn eller emojies',
        },
        recipient: {
            consentGiven: 'Samtykke givet',
            consentCanBeGiven: 'Samtykke kan gives',
            consentDenied: 'Samtykke afvist',
            userDoesNotHavePhoneNumber: 'Denne bruger har ikke et registreret tlf. nummer',
            messageReceivedBy: 'Beskeden blev modtaget af {{name}}',
            messagePendingFor: 'Beskeden er stadig på vej til {{name}}',
            messageFailedFor: 'Beskeden blev ikke modtaget af {{name}}',
            otherRoles: 'Andre roller',
            uniId: 'UNI-ID',
            giveConsentForUser: 'Giv samtykke for brugeren',
        },
        recipientType: {
            showMore: 'Vis {{count}} flere',
        },
        messageValidationErrorsModal: {
            heading: 'Kan ikke sende',
            text: 'Beskeden kunne ikke sendes, grundet de understående årsager:',
            goBack: 'Tilbage',
        },
        confirmSendMessageModal: {
            header: 'Bekræft afsendelse',
            receivesMessage: 'modtager beskeden',
            notReceiveMessageA: 'modtager',
            notReceiveMessageB: 'ikke',
            notReceiveMessageC: 'beskeden',
            messagesTotal: 'beskeder bliver sendt i alt',
            haveNotConsented: 'har ikke givet samtykke',
            areMissingPhoneNumber: 'har ikke et registreret tlf. nummer',
            cancel: 'Afbryd',
            send: 'Send besked',
        },
        consentBox: {
            header: 'Giv samtykke',
            people: 'personer',
            text: 'Nogle personer har ikke besluttet samtykke endnu. Du kan give samtykke på vegne af disse personer.',
            buttonText: 'Giv samtykke',
        },
        confirmBackModal: {
            header: 'Er du sikker?',
            text: 'De ændringer du har lavet bliver ikke gemt, hvis du vælger at gå tilbage!',
            cancel: 'Afbryd',
            confirm: 'Ja!',
        },
        // Messages
        errors: {
            requestCreateGroupFailed: 'Anmodningen om at oprette gruppen fejlede.',
            requestSendMessageFailed: 'Anmodningen om at sende beskeden til gruppen fejlede.',
            requestBlacklisted: 'Skolen er blokeret fra at sende beskeder. Genindlæs venligst siden.',
            noSelectedRecipients: 'Du skal vælge mindst én modtager eller gruppe af modtagere.',
            missingGroupName: 'Du skal angive et navn til tråden.',
            groupNameTooLong: 'Det indtastet navn til tråden er for langt.',
            messageTextEmpty: 'Beskeden må ikke være tom.',
            messageTextTooLong: 'Beskeden må ikke være over 10.000 tegn langt.',
        },
    },
}